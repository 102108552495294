.about__container {
  margin: 0;
  padding: 0;
  transition: all ease 300ms;
}

.about__mob_head {
  opacity: 0;
  visibility: hidden;
  display: none;
  margin-top: 4rem;
}

.about__grid {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 4rem;
}

.about__banner {
  position: relative;
  margin-left: 2rem;
  width: max-content;
}

.decor-1 {
  position: absolute;
  top: 8rem;
  left: -2rem;
  width: 9rem;
  height: 9rem;
  border: 2px solid #B3B3B2 ;
}

.decor-2 {
  position: absolute;
  top: 2rem;
  right: -2rem;
  width: 7.5rem;
  height: 7.5rem;
  border: 2px solid #B3B3B2 ;
}

.about__story {
  margin-top: 4rem;
}

.about_column .about__image {
  margin-top: 4rem;
}

.extra__content {
  position: relative;
  margin-top: 2rem;
  transition: all ease 300ms;
}

.read-more-link{
  /* text-decoration: underline; */
  width: max-content;
  cursor: pointer;
  position: flex;
  margin-left: auto;
  margin-right: 0;
  padding: 0 2rem;
  border: 2px solid #202242;
  transition: all ease 300ms;
  color: #202242;
}

.read-more-link:hover {
  background-color: #202242;
  color: #fff;
}

.read-more-link h2 {
  /* font-family: 'Josefin Slab', serif; */
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 3rem;
  letter-spacing: 2px;
}

/* media query for medium size devices */
@media screen and (max-width: 1024px) {
  .about__mob_head {
    opacity: 1;
    visibility: visible;
    display: block;
  }

  .about__grid {
    grid-template-columns: 1fr;
  }

  .about__banner {
    opacity: 0;
    display: none;
  }

  .about__story {
    margin-top: 2rem;
  }

  .about__ext {
    opacity: 0;
    display: none;
  }

  .extra__content {
    margin: 2rem 0;
  }
}


