.statement__container {
  height: auto;
  margin: 0 0 4rem 0;
  padding: 0;
  transition: all ease 300ms;
  position: relative;
}

.statement__grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 5rem;
}

.statement__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8rem;
  position: relative;
}

.vision__banner {
  margin: auto 0 0 auto;
}

.statement__column li {
  margin-left: 1.5rem;
}

.statement__column ul span {
  font-size: 1.5rem;
  line-height: 2rem;
}

.mission__content {
  margin: auto auto 0 0;
}

.vision__content {
  margin: 0 0 auto auto;
  text-align: right;
}

.line__center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border: 1px solid #B3B3B2;
}

.vertical__line {
  top: 7rem;
  width: 0rem;
  height: 20rem;
}

.horizontal__line {
  width: 15rem;
  height: 0rem;
}

/* media query for medium size devices */
@media screen and (max-width: 1024px) {
  .statement__grid {
    row-gap: 4rem;
  }
  
  .statement__row {
    grid-template-columns: 1fr;
  }
  
  .vision__banner {
    visibility: hidden;
    display: none;
  }

  .mission__banner {
    visibility: hidden;
    display: none;
  }

  .center__cross {
    visibility: hidden;
    display: none;
  }

  .mission__content {
    margin: 4rem 0 0 0;
  }
}

@media screen and (max-width: 666px) {
  .statement__grid {
    row-gap: 2rem;
  }

  .vision__content h2 {
    text-align: left;
  }
}