.img__wrapper {
  display: flex;
  width: max-content;
  height: max-content;
  position: relative;
}

.img__wrapper img {
  display: block;
  position: relative;
  z-index: 1;
  height: auto;
}

.border__box {
  border: 2px solid #B3B3B2;
  z-index: 2;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100%;
  height: 100%;
  transition: all ease 300ms ;
}

/* .img__wrapper:hover > .border__box { */
.border__box:hover {
  position: absolute;
  top: 0;
  left: 0;
  border: 4px solid #B3B3B2;
}