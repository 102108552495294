.footer__container {
  max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
}

.footer__content {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
  width: auto;
  justify-content: space-between;
}

.footer__head {
  display: block;
}

.footer__seperator {
  height: 0;
  width: auto;
  border-top: 1px solid #ffffff80;
  margin: 2rem 0 1rem 0;
}

.footer__address {
  color: white;
	/* margin: 0.4rem auto 0.4rem; */
	max-width: 20rem;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 2;
	text-align: left;
}

.footer__contact {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  margin: 2rem 0;
}

.contact__detail {
  color: #fff;
  font-weight: 100;
}

.contact__detail span {
  font-weight: 400;
}

.contact__detail a {
  color: #fff;
  text-decoration: underline;
  transition: all 300ms ease;
}

.contact__detail a:hover {
  color: #FFBB5A;
}

.footer__menu {
  opacity: 1;
  visibility: visible;
}

.footer__menu ul {
  display: flex;
  flex-direction: column;
  width: max-content;
  margin-left: auto;
  margin-right: 0;
  text-align: right;
  align-items: flex-end;
}

.footer__menu ul li {
  height: auto;
}

.footer__menu ul li:after {
  margin-right: 1rem;
  bottom: 1.25rem;
}

.footer__credits {
  display: flex;
  flex-direction: row;
  column-gap: auto;
	border-top: 1px solid #ffffff80;
	padding: 2rem 0;
  justify-content: space-between;
}

.footer__copy {
  color: #cdcdcd;
	font-size: 0.8rem;
	text-align: left;
}

.dev__promo {
	font-size: 0.8rem;
	text-align: center;
  width: 100%;
  margin: 0 auto;
}

.dev__promo a {
  color: #cdcdcd;
  text-decoration: none;
  transition: all 300ms ease;
}

.dev__promo a:hover {
  color: #fff;
}