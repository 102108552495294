.form__bckg {
  width: 100vw;
  height: auto;
  min-height: fit-content;
  background: url('../../../public/assets/prayer-bckg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form__container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  transition: all ease 300ms;
}

.form__grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
}

.form__col {
  /* background-color: #ffbb5a; */
  width: 100%;
  position: relative;
}

.form__title {
  padding-top: 42%;
}

.form__title h2 {
  text-shadow: 0 4px 4px #00000080;
}

.form__title h2 span {
  color: #ffbb5a;
}

.form__form {
  width: 100%;
  padding: 4rem 0;
  margin: 4rem 0;
}

.form__form_grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
}

.form__form input, textarea {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1.5rem;
  border: 0px #000;
  padding: 0 1rem;
  background: #fff;
}

.form__form textarea {
  height: 5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.form__form textarea:hover, input:hover {
  outline: #aaa solid 2px;
}

.form__form textarea:focus, input:focus {
  outline: #ffbb5a solid 2px;
}

.form__form_message {
  color: #fff;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  /* text-align: right; */
}

.form__form_message span {
  font-size: 1.25rem;
}

.form__form button {
  width: auto;
  height: 3rem;
  background: #ffbb5a;
  color: #202242;
  transition: all ease 300ms;
  border-radius: 0.5rem;
  padding: 0 3rem;
  font-size: 1rem;
  border: 0px;
  outline: none;
}

.form__form button:hover {
  color: #fff;
}

.form__form_submit {
  float: right;
}

/* media query for medium size devices */
@media screen and (max-width: 1024px) {
  .form__bckg {
    height: auto;
  }

  .form__container {
    height: auto;
  }

  .form__grid {
    grid-template-columns: 1fr;
  }

  .form__title {
    margin-top: 4rem;
    padding: 0;
  }

  .form__form {
    position: relative;
    top: 0;
    transform: translateY(0);
    padding-bottom: 4rem;
    margin: 2rem 0 4rem 0;
  }
}

/* media query for small size devices */
@media screen and (max-width: 666px) {
  .form__form_grid {
    grid-template-columns: 1fr;
  }

  .form__form_submit {
    width: 100%;
  }

  .form__form_submit button {
    width: 100%;
  }
}