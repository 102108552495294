.belief__container {
  margin: 0;
  padding: 0;
  transition: all ease 300ms;
  width: 100%;
  margin-bottom: 4rem;
}

.belief__banner {
  position: relative;
  margin: 0 0 4rem 0;
  /* background: #202242; */
  background:  radial-gradient(circle at 87% 50%, white, #FFBB5A);
  width: 100%;
  height: clamp(12rem, 30vw, 20rem);
  display: flex;
  align-items: stretch;
}

.belief__banner_grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  /* background-color: red; */
  z-index: 2;
  justify-content: center;
  align-items: center;
}

.belief__heading {
  width: max-content;
  text-align: right;
}

.belief__heading h2 {
  font-size: clamp(4rem, 30vw, 10rem);
  font-weight: 400;
  /* color: #fff; */
}

.belief__heading span {
  font-family: 'Raleway', sans-serif;
  font-size: clamp(2.25rem, 12vw, 3rem);
  font-weight: 100;
  color: #fff;
  position: relative;
  bottom: 2rem;
}

.decor-3 {
  position: absolute;
  bottom: -1rem;
  left: 2rem;
  width: 9rem;
  height: 4rem;
  border: 2px solid #B3B3B2 ;
  z-index: 1;
}

.decor-4 {
  position: absolute;
  top: -1.25rem;
  left: 4rem;
  width: 24rem;
  height: 4rem;
  border: 2px solid #B3B3B2 ;
  z-index: 1;
}

.belief__grid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 4rem;
}

.belief__image {
  position: relative;
  width: 25rem;
  height: max-content;
}

.belief__story li {
  margin-left: 2rem;
}

.grid__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 46% 52%;
  column-gap: 2%;
  z-index: 2;
  background: #fff;
  background-clip: content-box;
}

.grid__mask__col {
  display: grid;
  grid-template-rows: 30% 68%;
  row-gap: 2%;
  width: 100%;
  height: 100%;
  /* border: 4px solid red; */
}

.grid__mask__row {
  /* fill: red; */
  width: 100%;
  height: 100%;
  /* border: 4px solid red; */
  background: #eaeaea;
  background-clip: content-box;
}

.quad1 {
  height: 80%;
  width: 80%;
  margin: auto 0 0 auto;
}

.quad4 {
  height: 60%;
  width: 60%;
}

.extra__content {
  margin-top: 2rem;
}

.extra__content .read-more-link {
  margin-top: 2rem;
}

/* media query for medium size devices */
@media screen and (max-width: 1024px) {
  .belief__grid {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
  
  .belief__image {
    opacity: 0;
    display: none;
  }

  .belief__banner_grid {
    grid-template-columns: 1fr;
  }

  .belief__banner_img {
    visibility: hidden;
    display: none;
  }

  .belief__banner {
    background: transparent;
    margin: 4rem 0 2rem 0;
  }

  .belief__heading h2 {
    color: #202242;
  }

  .belief__heading span {
    color: #202242;
  }
}

/* media query for larger size devices */
@media screen and (min-height: 1024px) {
  .form__bckg {
    height: auto;
  }

  .form__container {
    height: auto;
  }
}
