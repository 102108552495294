.carousel__container {
  width: 100%;
  /* height: calc(100vh - 7rem); */
}

.swiper__container {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.swiper__container div {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
}

.swiper-scrollbar {
  border-radius: 0px;
  background: #FFBB5A;
  display: block;
  margin: 0 auto;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
  z-index: 50;
  height: 5px;
  width: 40%;
  position: relative;
  left: 1%;
  bottom: -1rem;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 40%;
  position: relative;
  background: #202242;
  border-radius: 0px;
  left: 0;
  top: 0;
  z-index: 100;
}
